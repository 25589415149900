// NOTE(drew): this file is copied from
// https://github.com/sergiodxa/remix-utils/blob/main/src/client/cache-assets.ts,
// and modified to allow specifying the filePaths specifically. The remix
// manifest does not seem to contain all bundles, such as those loaded via
// React.Lazy. Additional changes include unwrapping the map/filter/map chains
// in get/remove/add to improve performance. It's especially noticeable on
// mobile devices or with a 4x (or more) slowdown in DevTools. Sped up anywhere
// from 2-8x!

export interface CacheAssetsOptions {
  /**
   * The name of the cache to use inside the browser Cache Storage
   * @default "assets"
   */
  cacheName?: string;
  /**
   * The path prefix for all build assets, if you used a subdomain ensure this
   * is only the pathname part.
   * @default "/build/"
   */
  buildPath?: string;

  /**
   * A function that returns an array of all the file paths to cache. The
   * default implementation looks at the remix manifest.
   * @default () => getFilePathsDefault()
   */
  getFilePaths?: () => string[];
}

/**
 * Caches all JS files built by Remix in a browser cache.
 * This will use the Remix manifest to determine which files to cache.
 * It will get every JS file, get all the already cached URLs, remove any
 * old file, and finally add the new files to the cache.
 *
 * **This can only be run inside entry.client**
 */
export async function cacheAssets({
  cacheName = 'assets',
  buildPath = '/build/',
  getFilePaths = getFilePathsDefault,
}: CacheAssetsOptions = {}) {
  const paths = getFilePaths();
  const cache = await caches.open(cacheName);
  const urls = await getCachedUrls(cache, buildPath);
  await removeOldAssets(cache, paths, urls);
  await addNewAssets(cache, paths, urls);
}

function getFilePathsDefault() {
  try {
    return unique([
      ...Object.values(window.__remixManifest.routes).flatMap((route) => {
        return [route.module, ...(route.imports ?? [])];
      }),
      window.__remixManifest.url,
      window.__remixManifest.entry.module,
      ...window.__remixManifest.entry.imports,
    ]);
  } catch (cause) {
    throw new Error('Failed to get file paths from Remix manifest', { cause });
  }
}

async function getCachedUrls(
  cache: Cache,
  buildPath: CacheAssetsOptions['buildPath'] = '/build/'
) {
  try {
    const keys = await cache.keys();

    const filteredPaths: string[] = [];
    const currentHostname = window.location.hostname;
    filteredPaths.length = keys.length;

    let validPathCount = 0;

    for (let i = 0, len = keys.length; i < len; i++) {
      const url = new URL(keys[i].url);
      if (
        url.hostname === currentHostname &&
        url.pathname.startsWith(buildPath)
      ) {
        filteredPaths[validPathCount++] = url.pathname;
      }
    }

    // Trim array to actual size
    filteredPaths.length = validPathCount;

    return filteredPaths;
  } catch (cause) {
    throw new Error('Failed to retrieve cached URLs', { cause });
  }
}

async function removeOldAssets(cache: Cache, paths: string[], urls: string[]) {
  try {
    // Convert paths to a Set for O(1) lookups
    const pathsSet = new Set(paths);

    // Prepare deletion promises array with pre-allocated size
    const deletionPromises = [];
    deletionPromises.length = urls.length;

    // Track actual number of items to delete
    let deleteCount = 0;

    // Find URLs that need to be deleted
    for (let i = 0, len = urls.length; i < len; i++) {
      const pathname = urls[i];
      if (!pathsSet.has(pathname)) {
        deletionPromises[deleteCount++] = cache.delete(pathname);
      }
    }

    // Trim array to actual size
    deletionPromises.length = deleteCount;

    // Only run Promise.all if we have items to delete
    if (deleteCount > 0) {
      await Promise.all(deletionPromises);
    }
  } catch (cause) {
    throw new Error('Failed to remove old assets from the cache', { cause });
  }
}

async function addNewAssets(cache: Cache, paths: string[], urls: string[]) {
  try {
    // Convert urls to a Set for O(1) lookups
    const urlsSet = new Set(urls);

    // Prepare array for new paths with pre-allocated size
    const newPaths = [];
    newPaths.length = paths.length;

    let newPathCount = 0;

    // Find paths that need to be added
    for (let i = 0, len = paths.length; i < len; i++) {
      const path = paths[i];
      if (!urlsSet.has(path)) {
        newPaths[newPathCount++] = path;
      }
    }

    // Trim array to actual size
    newPaths.length = newPathCount;

    // Only call addAll if we have new paths
    if (newPathCount > 0) {
      await cache.addAll(newPaths);
    }
  } catch (cause) {
    throw new Error('Failed to add new assets to the cache', { cause });
  }
}

function unique<Value>(array: Value[]): Value[] {
  return [...new Set(array)];
}
