// Shim taken from https://gist.github.com/paullewis/55efe5d6f05434a96c36 and
// modified to include `timeout` option, which just ends up being the setTimeout
// delay in the event there is no support!

const win = typeof window !== 'undefined' ? window : globalThis;

export const safeRequestIdleCallback =
  'requestIdleCallback' in win
    ? win.requestIdleCallback
    : fallbackRequestIdleCallback;

export const safeCancelIdleCallback =
  'cancelIdleCallback' in win
    ? win.cancelIdleCallback
    : fallbackCancelIdleCallback;

function fallbackRequestIdleCallback(
  cb: (deadline: IdleDeadline) => void,
  options: { timeout: number } = { timeout: 50 }
) {
  return setTimeout(function () {
    const start = Date.now();
    cb({
      didTimeout: false,
      timeRemaining: function () {
        return Math.max(0, options.timeout - (Date.now() - start));
      },
    });
  }, options.timeout) as unknown as number;
}

function fallbackCancelIdleCallback(id: number) {
  clearTimeout(id);
}
