export type ClientEnv = typeof CLIENT_SAFE_ENV;

export const CLIENT_SAFE_ENV = {
  BUILD_SHA: process.env.BUILD_SHA,

  AGORA_APP_ID: process.env.AGORA_APP_ID,
  AGORA_CODEC: process.env.AGORA_CODEC,
  AGORA_LOG_LEVEL: process.env.AGORA_LOG_LEVEL,
  AGORA_LOG_UPLOAD_ENABLED: process.env.AGORA_LOG_UPLOAD_ENABLED,

  API_SERVICE: process.env.API_SERVICE,
  API_WS_SERVICE: process.env.API_WS_SERVICE,
  API_SERVICE_INTERNAL: process.env.API_SERVICE_INTERNAL,
  API_PAGE_SIZE: process.env.API_PAGE_SIZE,

  APP_BASE_URL: process.env.APP_BASE_URL,

  BLOCK_SAFARI: process.env.BLOCK_SAFARI,

  BUCKET_DOMAIN: process.env.BUCKET_DOMAIN,

  CELEBRATIONS_TAG_SLUG: process.env.CELEBRATIONS_TAG_SLUG,

  CHAT_LOAD_HISTORY: process.env.CHAT_LOAD_HISTORY,
  CHAT_STREAM_CHAT_KEY: process.env.CHAT_STREAM_CHAT_KEY,

  CLOUD_HOSTING_ENABLED: process.env.CLOUD_HOSTING_ENABLED,

  CROWD_FRAMES_SERVICE: process.env.CROWD_FRAMES_SERVICE,

  ELEVEN_LABS_DEFAULT_VOICE_ID: process.env.ELEVEN_LABS_DEFAULT_VOICE_ID,

  EVENT_LIVE_GAME_PACK: process.env.EVENT_LIVE_GAME_PACK,

  FAVORITE_TAG_ID: process.env.FAVORITE_TAG_ID,
  FAQ_GROUP_IDS: process.env.FAQ_GROUP_IDS,
  ANONYMOUS_FAQ_GROUP_IDS: process.env.ANONYMOUS_FAQ_GROUP_IDS,

  ONBOARDING_HUBSPOT_URL: process.env.ONBOARDING_HUBSPOT_URL,
  INTROS_HUBSPOT_URL: process.env.INTROS_HUBSPOT_URL,
  EVENT_PLANNING_HUBSPOT_URL: process.env.EVENT_PLANNING_HUBSPOT_URL,
  HUBSPOT_SUBSCRIPTION_CANCEL_SUPPORT_CALL_URL:
    process.env.HUBSPOT_SUBSCRIPTION_CANCEL_SUPPORT_CALL_URL,
  HUBSPOT_SUBSCRIPTION_CANCEL_OFFER_ACCEPTED_URL:
    process.env.HUBSPOT_SUBSCRIPTION_CANCEL_OFFER_ACCEPTED_URL,
  HUBSPOT_HEADCOUNT_URL: process.env.HUBSPOT_HEADCOUNT_URL,
  HUBSPOT_EVENT_PURCHASE_PRE_CALL_URL:
    process.env.HUBSPOT_EVENT_PURCHASE_PRE_CALL_URL,

  FIREBASE_API_KEY: process.env.FIREBASE_API_KEY,
  FIREBASE_AUTH_URL: process.env.FIREBASE_AUTH_URL,
  FIREBASE_DATABASE_LOGGING_ENABLED:
    process.env.FIREBASE_DATABASE_LOGGING_ENABLED,
  FIREBASE_DATABASE_URL: process.env.FIREBASE_DATABASE_URL,
  FIREBASE_FUNCTIONS_PREFIX: process.env.FIREBASE_FUNCTIONS_PREFIX,
  FIREBASE_PROJECT_ID: process.env.FIREBASE_PROJECT_ID,
  FIREBASE_USE_EMULATOR: process.env.FIREBASE_USE_EMULATOR,

  FREE_EMAIL_FILTERING_ENABLED: process.env.FREE_EMAIL_FILTERING_ENABLED,

  GAME_PACK_IMPORT_ENABLED: process.env.GAME_PACK_IMPORT_ENABLED,

  GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,

  HOME_URL_OR_PATHNAME: process.env.HOME_URL_OR_PATHNAME,

  INTERCOM_APP_ID: process.env.INTERCOM_APP_ID,

  KIBANA_BASE_URL: process.env.KIBANA_BASE_URL,
  KIBANA_VENUE_STATS_DASHBOARD_ID: process.env.KIBANA_VENUE_STATS_DASHBOARD_ID,
  KIBANA_WEB_APP_LOG_INDEX: process.env.KIBANA_WEB_APP_LOG_INDEX,

  LOG_CONSOLE_ENABLED: process.env.LOG_CONSOLE_ENABLED,
  LOG_CONSOLE_FORMAT: process.env.LOG_CONSOLE_FORMAT,
  LOG_CONSOLE_CTRL_ENABLED: process.env.LOG_CONSOLE_CTRL_ENABLED,
  LOG_ENABLE_SCOPES: process.env.LOG_ENABLE_SCOPES,
  LOG_HTTP_ENDPOINT: process.env.LOG_HTTP_ENDPOINT,
  LOG_LEVEL: process.env.LOG_LEVEL,
  LOG_MAX_BACKOFF_MS: process.env.LOG_MAX_BACKOFF_MS,
  LOG_MAX_DELIVERY_ATTEMPTS: process.env.LOG_MAX_DELIVERY_ATTEMPTS,
  LOG_MAX_ENTRY_COUNT_PER_REQUEST: process.env.LOG_MAX_ENTRY_COUNT_PER_REQUEST,
  LOG_QUEUE_FLUSH_MS: process.env.LOG_QUEUE_FLUSH_MS,

  LUNA_PARK_ORG_ID: process.env.LUNA_PARK_ORG_ID,

  // TODO: this is probobably not used currently
  MAINTENANCE_ENABLED: process.env.MAINTENANCE_ENABLED,

  MISC_RUN_AT_LEASE: process.env.MISC_RUN_AT_LEASE,
  MISC_SKIP_OND_GAME_CHECKLIST: process.env.MISC_SKIP_OND_GAME_CHECKLIST,

  OFFBOARD_ENABLED: process.env.OFFBOARD_ENABLED,

  PROGRAM_INTROS_ROUND_TIME_TO_MINUTES:
    process.env.PROGRAM_INTROS_ROUND_TIME_TO_MINUTES,
  PROGRAM_WATER_COOLER_ROUND_TIME_TO_MINUTES:
    process.env.PROGRAM_WATER_COOLER_ROUND_TIME_TO_MINUTES,

  RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY,

  REGISTRATION_MARKETING_TRACKING_ENABLED:
    process.env.REGISTRATION_MARKETING_TRACKING_ENABLED,

  SEGMENT_WRITE_KEY: process.env.SEGMENT_WRITE_KEY,

  SENTIMENT_BASELINE_CAPTURE: process.env.SENTIMENT_BASELINE_CAPTURE,
  SENTIMENT_CONFIDENCE_THREASHOLD: process.env.SENTIMENT_CONFIDENCE_THREASHOLD,
  SENTIMENT_COOLDOWN: process.env.SENTIMENT_COOLDOWN,
  SENTIMENT_EXPRESSIONS: process.env.SENTIMENT_EXPRESSIONS,
  SENTIMENT_UPLOAD_ENABLED: process.env.SENTIMENT_UPLOAD_ENABLED,
  SENTIMENT_UPLOAD_LIMITS: process.env.SENTIMENT_UPLOAD_LIMITS,

  SENTRY_DSN: process.env.SENTRY_DSN,
  SENTRY_SAMPLE_RATE: process.env.SENTRY_SAMPLE_RATE,
  SENTRY_API_ORGANIZATION: process.env.SENTRY_API_ORGANIZATION,

  SESSION_REALTIME_SILENT_REFRESH_ENABLED:
    process.env.SESSION_REALTIME_SILENT_REFRESH_ENABLED,
  SESSION_SNAPSHOT_CHECK_INTERVAL: process.env.SESSION_SNAPSHOT_CHECK_INTERVAL,
  SESSION_TRACKING_ENABLED: process.env.SESSION_TRACKING_ENABLED,

  SLACK_ADMIN_TOOL_ENABLED: process.env.SLACK_ADMIN_TOOL_ENABLED,
  SLACK_BOT_ICON_URL: process.env.SLACK_BOT_ICON_URL,
  SLACK_BOT_USERNAME: process.env.SLACK_BOT_USERNAME,
  SLACK_CLIENT_ID: process.env.SLACK_CLIENT_ID,
  SLACK_MEDIA_VIEWER_ENABLED: process.env.SLACK_MEDIA_VIEWER_ENABLED,

  STAGE_MAX_MEMBERS: process.env.STAGE_MAX_MEMBERS,

  STRIPE_PUBLIC_KEY: process.env.STRIPE_PUBLIC_KEY,
  STRIPE_PUBLISHABLE_KEY: process.env.STRIPE_PUBLISHABLE_KEY,
  STRIPE_MINIMAL_PMC_ID: process.env.STRIPE_MINIMAL_PMC_ID,

  TEAM_AUTO_REJOIN_TIMEOUT: process.env.TEAM_AUTO_REJOIN_TIMEOUT,
  TEAM_INCOOLDOWN: process.env.TEAM_INCOOLDOWN,
  TEAM_MAX_MEMBERS: process.env.TEAM_MAX_MEMBERS,
  TEAM_RANDOIMIZER_COUNTDOWN: process.env.TEAM_RANDOIMIZER_COUNTDOWN,
  TEAM_RANDOIMIZER_DEFAULT_TO_CURRENT_TEAM:
    process.env.TEAM_RANDOIMIZER_DEFAULT_TO_CURRENT_TEAM,

  TYPE_FORM_ID_COLLECTIVE: process.env.TYPE_FORM_ID_COLLECTIVE,
  TYPE_FORM_ID_LIVE_BOOKING: process.env.TYPE_FORM_ID_LIVE_BOOKING,
  TYPE_FORM_ID_OND: process.env.TYPE_FORM_ID_OND,
  TYPE_FORM_ID_LIVE_2: process.env.TYPE_FORM_ID_LIVE_2,
  TYPE_FORM_ID_COHOST: process.env.TYPE_FORM_ID_COHOST,
  TYPE_FORM_ID_PLAN_UPGRADE: process.env.TYPE_FORM_ID_PLAN_UPGRADE,
  TYPE_FORM_ID: process.env.TYPE_FORM_ID,

  USER_CONN_CLEANUP_TIMEOUT: process.env.USER_CONN_CLEANUP_TIMEOUT,

  VIEW_SHOW_QUICK_ACCESS: process.env.VIEW_SHOW_QUICK_ACCESS,

  X_STORAGE_CLIENT_ORIGIN: process.env.X_STORAGE_CLIENT_ORIGIN,
  X_STORAGE_ENABLED: process.env.X_STORAGE_ENABLED,
  X_STORAGE_HUB_ORIGIN: process.env.X_STORAGE_HUB_ORIGIN,

  CONSOLE_CTRL_ENABLED: process.env.CONSOLE_CTRL_ENABLED,

  ZOOM_CLIENT_ID: process.env.ZOOM_CLIENT_ID,
  ZOOM_CLIENT_SECRET: process.env.ZOOM_CLIENT_SECRET,
  ZOOM_HOST: process.env.ZOOM_HOST,

  APP_SECURE: process.env.APP_SECURE,

  MUSIC_PLAYLIST_ID: process.env.MUSIC_PLAYLIST_ID,
  LIVE2_DEFAULT_BACKGROUND_ASSET_ID:
    process.env.LIVE2_DEFAULT_BACKGROUND_ASSET_ID,
  UGC_TEMPLATES_TAG_ID: process.env.UGC_TEMPLATES_TAG_ID,

  MUSIC_LIBRARY_SHARED_MEDIA_PLAYLIST_ID:
    process.env.MUSIC_LIBRARY_SHARED_MEDIA_PLAYLIST_ID,

  LEARNING_USE_PAUSE_MANAGEMENT: process.env.LEARNING_USE_PAUSE_MANAGEMENT,
};
