import { useNavigation } from '@remix-run/react';
import { captureException } from '@sentry/remix';
import { useEffect, useRef } from 'react';

import { getFeatureQueryParamArray } from '../../../src/hooks/useFeatureQueryParam';
import { assertExhaustive } from '../../../src/utils/common';
import {
  safeCancelIdleCallback,
  safeRequestIdleCallback,
} from '../../../src/utils/requestIdleCallback';
import { cacheAssets } from './cache-assets';

export function Precache(props: { files: string[] }) {
  const navigation = useNavigation();
  const latestNavigation = useRef(navigation);
  latestNavigation.current = navigation;
  const attempted = useRef(false);
  const latest = useRef<string[] | null>(null);
  latest.current = props.files;

  useEffect(() => {
    const files = latest.current;

    if (attempted.current) return;

    attempted.current = true;

    if (!files) return;
    if (!window.caches) return;

    const param = getFeatureQueryParamArray('precache');

    switch (param) {
      case 'disabled':
        return;
      case 'production':
        if (!import.meta.env.PROD) return;
        break;
      case 'any':
        break;
      default:
        assertExhaustive(param);
    }

    const id = safeRequestIdleCallback(
      async () => {
        if (import.meta.env.DEV) console.log('Precache Starting');
        try {
          await cacheAssets({
            getFilePaths: () => files,
            buildPath: '/assets/',
          });
          if (import.meta.env.DEV) console.log('Precache Completed');
        } catch (cause) {
          if (latestNavigation.current.state === 'idle') {
            // An error could be because the user is navigating while precaching
            // is in progress. Prevent logging errors in this known case.
            captureException(cause);
            console.error(cause);
          }
        }
      },
      // For browsers that don't support this API, this will instead be when it
      // fires always.
      { timeout: 5000 }
    );

    return () => {
      safeCancelIdleCallback(id);
    };
  }, []);

  return null;
}
